import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"drawer-category-name"},[_vm._v("設定")]),_c(VCard,{staticClass:"pa-5"},[_c('ul-content-header',{attrs:{"heading-main":_vm.headingMain,"heading-sub":_vm.headingSub}}),_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"grow"},[_c('ul-breadcrumbs',{attrs:{"items":_vm.breadCrumbs}})],1)],1)],1),_c(VContainer,{staticClass:"text-center",attrs:{"fluid":""}},[(_vm.result == 0)?_c(VProgressCircular,{attrs:{"size":50,"color":"primary","indeterminate":""}}):_vm._e(),(_vm.result == 1)?_c('p',[_vm._v("ストライプ加盟店登録が完了いたしました。")]):_vm._e(),(_vm.result == 2)?_c('p',[_vm._v("エラーが発生したため、加盟店登録ができませんでした。")]):_vm._e(),(_vm.result == 3)?_c('p',[_vm._v("正しいアクセスではありません。")]):_vm._e(),(_vm.result != 0)?_c(VBtn,{staticClass:"mt-10",attrs:{"rounded":"","color":"primary","to":"/stripe/"}},[_vm._v("ストライプ一覧に戻る")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }