import { Component, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import MainShopGet from "@/store/main-shop/get";
import AppNameListGet from "@/store/app-name-list/get";
import service from "@/api/service";
import { BusinessServiceStorage } from "@/store/business-service-storage";

@Component({
  components: { UlContentHeader, UlBreadcrumbs }
})
export default class Connect extends Vue {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "Stripe";
  headingSub = "stripe";
  breadCrumbs = [{ text: "設定 > Stripe > Stripe加盟店登録", disabled: true }];

  result = 0;

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------

  // ローディングステータス
  isLoading = false;

  get mainShopItems() {
    return MainShopGet.getItems;
  }

  get appItems() {
    return AppNameListGet.getItems;
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    const storage = BusinessServiceStorage.getLocalStorage();
    if(!this.$route.query.code){
      return this.result = 3;
    }
    var req = {
      code: this.$route.query.code,
      business_code: storage.code
    }
    const response = await service.post("/stripe-connect", req);
    if(response){
      if(!response.data.error){
        this.result = 1;
      }else{
        this.result = 2;
      }
    }else{
      this.result = 3;
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await MainShopGet.clearResponse();
  }


}
